import React from 'react';

import LinkBtn from '@components/link-btn';
import { dexTradingCards } from './dexTradingSection.content';

const DexTrading = () => (
  <div className="bg-white pt-10 sm:pt-16 lg:pt-14">
    <div className="relative bg-gray-900 py-12">
      <div className="absolute inset-0 h-1/2" />
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="max-w-5xl mx-auto">
          <dl className="rounded-lg sm:grid sm:grid-cols-3">
            {dexTradingCards.map((card) => (
              <div
                key={card.id}
                className="flex flex-col p-6 text-center rounded-lg"
              >
                <dd className="text-5xl pb-6 font-extrabold text-indigo-400">
                  <img
                    className="px-9 w-full lg:h-full"
                    src={card.img}
                    alt={card.title}
                  />
                </dd>
                <dt className="h-11 my-2 text-xl leading-6 font-medium text-coolGray-500">
                  {card.title}
                </dt>
                <dd className="font-medium text-coolGray-400">{card.desc}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
    <div className="my-12 bg-white">
      <div className="max-w-7xl mx-auto px-4 pb-10 sm:px-6 lg:px-8">
        <div className="max-w-5xl mx-auto text-center">
          <h2 className="font-bold text-green-300 leading-tight text-5xl">
            Join thousand of others <br />{' '}
            <span className="font-bold text-coolGray-800 ">
              {' '}
              exploring DEX trading strategies{' '}
            </span>
          </h2>
        </div>
      </div>
      <div className="flex justify-center">
        <LinkBtn
          className="px-24"
          href="https://app.botswap.fi/"
          text="Explore the app"
        />
      </div>
    </div>
  </div>
);

export default DexTrading;
