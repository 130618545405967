import { lock, analyticsGear, tunePointer } from '@images';

export const dexTradingCards = [
  {
    id: 1,
    img: lock,
    title: 'Highly Secure',
    desc: 'Battle tested infrastructure and smart contracts audited by industry leading firms',
  },
  {
    id: 2,
    img: analyticsGear,
    title: 'Intuitive interface with support to setup',
    desc: 'Tool tips and setup wizard enables everyone to participate in DeFi yield and return opportunties',
  },
  {
    id: 3,
    img: tunePointer,
    title: 'Fine tune and control',
    desc: 'Optimise your trading, liquidity and arbitrage settings on the fly',
  },
];
