import React from 'react';

import Layout from '@components/layout';
import HeroSection from '@components/hero-section';

import HowItWorksSection from '@components/how-it-works-footer-section';
import DexTradingSection from '@components/dex-trading-section';

import SEO from '@components/seo';
import { botQuestions } from '@images';

const Ecosystem = () => {
  return (
    <Layout headerClass="absolute z-50">
      <SEO
        description="Learn about how our DEX automation smart contract creates an automation layer between your wallet and DEX contract interactions"
        title="BotSwap DeFi automation: how it works"
      />
      <main>
        <HeroSection
          title={<span className="block">How it works</span>}
          subTitle={
            <>
              Our DEX automation smart contract creates an automation layer
              between your wallet and DEX contract interactions
            </>
          }
          img={botQuestions}
        />
        <HowItWorksSection />
        <DexTradingSection />
      </main>
    </Layout>
  );
};

export default Ecosystem;
