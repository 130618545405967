import React from 'react';

import Card from '@components/card';
import { walkthoughCards } from './howItWorks.content';

const HowItWorks = () => {
  return (
    <div className="bg-white mb-14 pt-20 sm:pt-16">
      <div className="mt-4 pb-10 bg-white sm:pb-16 lg:pb-10">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="max-w-5xl mx-auto text-center">
            <h2 className="font-bold text-gray-800 leading-tight text-5xl">
              How it works
            </h2>
          </div>
        </div>
      </div>
      <div className="relative">
        <div className="relative max-w-7xl mx-auto  sm:px-6 lg:px-8">
          <div className="max-w-5xl mx-auto">
            <dl className="bg-white gap-x-14 sm:grid sm:grid-cols-3">
              {walkthoughCards.map((card) => (
                <Card
                  key={card.id}
                  img={card.img}
                  title={card.title}
                  rounded={true}
                />
              ))}
            </dl>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
